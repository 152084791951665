<template>
    <v-card class="ma-5">
        <v-form v-model="valid" ref="form">
            <v-container>
                <v-row class="my-4 align-center">
                    <h1 class="ms-2">PRESUPUESTO</h1>
                    <v-spacer></v-spacer>
                    <v-btn @click="enviar" v-if="idPresupuesto" color="primary" class="me-2">ENVIADO<v-icon>mdi-send</v-icon></v-btn>
                    <v-btn to="/presupuestos" color="primary" class="me-2"><v-icon>mdi-arrow-left</v-icon>VOLVER</v-btn>
                    <v-btn v-if="idPresupuesto" @click="del" color="error" class="me-2"><v-icon>mdi-trash-can-outline</v-icon>ELIMINAR</v-btn>
                </v-row>
                <AuxInput class="mx-2 no-gutters" title="ENTRADA PPTO">
                    <v-menu ref="menu" v-model="menu" :return-value.sync="date" transition="scale-transition" offset-y
                        min-width="auto" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :value='presupuesto.fecha != null ? ((new Date(presupuesto.fecha).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(presupuesto.fecha).getDate()).slice(-2)) + (new Date(presupuesto.fecha).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(presupuesto.fecha).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker @click:date="menu = false" no-title v-model="presupuesto.fecha" :first-day-of-week="1" scrollable>
                        </v-date-picker>
                    </v-menu>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="ESTADO PPTO">
                    <v-select filled v-model="presupuesto.estado" dense hide-details :items="estados" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="CONFIRMADO">
                    <v-select filled v-model="presupuesto.confirmado" dense hide-details :items="confirmado" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="FECHA EVENTO">
                    <v-menu ref="menu" v-model="menu2" :return-value.sync="date" transition="scale-transition" offset-y
                        min-width="auto" :close-on-content-click="false">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field :value='presupuesto.fechaEv != null ? ((new Date(presupuesto.fechaEv).toLocaleDateString("default", { weekday : "short"}).toUpperCase() + " - " + (("0" + new Date(presupuesto.fechaEv).getDate()).slice(-2)) + (new Date(presupuesto.fechaEv).toLocaleString("default", { month: "short" }).toUpperCase())) + " - " + new Date(presupuesto.fechaEv).getFullYear()) : null' append-icon="mdi-calendar" readonly filled hide-details dense v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker @click:date="menu2 = false" no-title v-model="presupuesto.fechaEv" :first-day-of-week="1" scrollable>
                        </v-date-picker>
                    </v-menu>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="TIPO EVENTO">
                    <v-select filled v-model="presupuesto.tipo" dense hide-details :items="tipoEventos" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="CLIENTE">
                    <v-text-field filled v-model="presupuesto.cliente" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="ESPACIO">
                    <v-select filled v-model="presupuesto.espacio" dense hide-details :items="espacios" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="UBICACIÓN">
                    <v-text-field filled v-model="presupuesto.ubicacion" dense hide-details
                        class="v-superdense-input"></v-text-field>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="TIPO PPTO">
                    <v-select filled v-model="presupuesto.tipoPpto" dense hide-details :items="tipoPptos" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="INTRODUCE">
                    <v-select filled v-model="presupuesto.introduce" dense hide-details :items="introduce" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="REALIZA">
                    <v-select filled v-model="presupuesto.realiza" dense hide-details :items="realiza" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="SUPERVISA">
                    <v-select filled v-model="presupuesto.supervisa" dense hide-details :items="supervisa" :item-text="item => item.title" :item-value="item => item.val" class="v-superdense-input"></v-select>
                </AuxInput>
                <AuxInput class="mx-2 no-gutters" title="NOTAS">
                    <v-textarea v-model="presupuesto.notas" filled dense hide-details="auto" no-resize></v-textarea>
                </AuxInput>
                <v-btn color="primary" class="my-2" @click="save"><v-icon>mdi-floppy</v-icon>GUARDAR</v-btn>
            </v-container>
        </v-form>
        <ConfirmDialog ref="confirm" />
    </v-card>
</template>
<script>

import { req } from "@/utils/validations.js";
export default {
    props: ["idPresupuesto"],
    components: {
        AuxInput: () => import("@/components/AuxInput.vue"),
        ConfirmDialog: () => import("@/components/ConfirmDialog.vue"),
    },
    computed: {
        auxModel: {
            get() {
                return this.value;
            },
            set(v) {
                this.$emit("input", v);
            },
        },
    },
    data() {
        return {
            idEvento: null,
            idAdHoc: null,
            pte: false,
            rules: { req },
            saving: false,
            date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
            estados:[
                {title:"PTE SUPERVISIÓN",val:1},
                {title:"NO REALIZADO",val:2}],
            confirmado:[
                {title:"NO", val : 0},
                {title:"SI", val : 1},],
            tipoEventos:[
                {title:"BODA", val : 1},
                {title:"COMUNIÓN", val : 2},
                {title:"CORPORATIVO", val : 4},
                {title:"EVENTO", val : 3},
                {title:"PRUEBA BODA", val : 5}],
            espacios:[
                {title: "PISCIS", val : 1},
                {title: "HOTEL", val : 2},
                {title: "LAUS", val : 3},
                {title: "LALANNE", val : 4},
                {title: "CATERING ARAGÓN", val : 9},
                {title: "CATERING CATALUÑA", val : 10},
                {title: "LLOTJA", val : 12},
                {title: "VDV", val : 13},
                {title: "PALACIO", val : 5}, 
                {title: "PANTICOSA", val : 50}, 
                {title: "SOMMOS", val : 60}, 
                {title: "MAS DE PONENT", val : 16}, 
                {title:"POR DEFINIR", val : 11},
            ],
            tipoPptos:[
                {title : "NUEVO", val : 1}, 
                {title : "REVISIÓN", val : 2}
            ],
            introduce:[
                {title:"LAURA", val : 1}, 
                {title:"BERTA", val : 2}, 
                {title:"ISABEL", val : 3}, 
                {title:"MONICA", val : 4}, 
                {title:"JAVI", val : 5}, 
                {title:"JOSE", val : 6}, 
                {title:"BELEN", val : 10}, 
                {title:"OTROS", val : 7}
            ],
            realiza:[
                {title:"LAURA", val : 1}, 
                {title:"BERTA", val : 2}, 
                {title:"ISABEL", val : 3}, 
                {title:"MONICA", val : 4}, 
                {title:"BELEN", val : 10}, 
                {title:"OTROS", val : 7}
            ],
            supervisa:[
                {title:"JAVI", val : 5}, 
                {title:"JOSE", val : 6}, 
                {title:"LAURA", val : 1},                
                {title:"PTE ASIGNAR", val : 10},
            ],
            menu: false,
            menu2: false,
            valid: true,
            presupuesto: {
                fecha: null,
                estado: null,
                confirmado: null,
                fechaEv: null,
                tipo: null,
                cliente: null,
                espacio: null,
                ubicacion: null,
                tipoPpto: null,
                introduce: null,
                realiza: null,
                supervisa: null,
                notas: null,
            }
        };
    },
    methods:{
        async del(){
            const confirmation = (await this.$refs.confirm.open(
                "Eliminar presupuesto",
                "Se eliminará el presupuesto. Esta acción NO se puede deshacer.",
                { color: "primary" }
                ));
            if (!confirmation) return;

            try {
                this.saving = true;
                const id = await axios({
                    method: 'DELETE',
                    url: `${process.env.VUE_APP_API_URL}/presupuestos/${this.idPresupuesto}`,
                })
                this.saving = false;

                this.$root.$emit("snack", "Eliminado correctamente");
                this.$router.push({ name: 'presupuestos'}).catch(()=>{})

            } catch (err){
                this.saving = false;
                console.error(err);
                this.$root.$emit("snack", "No se ha podido eliminar correctamente");
            }
        },
        enviar(){
            this.presupuesto.estado = 3
            this.save(true);
        },
        async save(skip) {
            if (!this.$refs.form.validate()) {
                this.$root.$emit("snack", "ERROR: Asegurate de haber rellenado los campos obligatorios");
                return;
            }
            const confirmation =
                skip ||
                (await this.$refs.confirm.open(
                "Guardar los cambios",
                "¿Estás seguro de que quieres guardar este presupuesto?",
                { color: "primary" }
                ));
            if (!confirmation) return;

            try {
                this.saving = true;
                if(this.pte && this.presupuesto.estado == 3) this.presupuesto.enviado = new Date().toISOString().split('T')[0]
                const id = await axios({
                        method: 'POST',
                        url: `${process.env.VUE_APP_API_URL}/presupuestos/nuevo`,
                        data: {
                            items: JSON.stringify({datos : this.presupuesto, idPresupuesto : this.idPresupuesto, idEvento : this.idEvento, idAdHoc : this.idAdHoc, notOficial : false }),
                        }
                    })
                this.saving = false;

                this.$root.$emit("snack", "Se han guardado los cambios correctamente");
                this.$router.push({ path: '/presupuestos'}).catch(()=>{})

            } catch (err){
                this.saving = false;
                console.error(err);
                this.$root.$emit("snack", "No se han podido guardar los cambios");
            }
		},
        async getPresupuesto() {
            if (this.idPresupuesto){
                try {
                    const { data } = await axios({
                            method: 'GET',
                            url: `${process.env.VUE_APP_API_URL}/presupuestos/${this.idPresupuesto}`,
                        })
                    this.$root.$emit("snack", "Presupuesto cargado correctamente");
                    this.idEvento = data.idEvento
                    this.idAdHoc = data.idAdHoc
                    this.presupuesto = data.datos;
                    if (!this.presupuesto.fecha) this.presupuesto.fecha = new Date();
                    if (this.presupuesto.estado != 3) this.pte = true;
                } catch (err){
                    this.saving = false;
                    console.error(err);
                    this.$root.$emit("snack", "No se han podido cargar los datos de este presupuesto");
                }
            }
            else {
                this.presupuesto.fecha = new Date().toISOString().split('T')[0];
            }
        }
    },
    mounted(){
        this.getPresupuesto()
    }
};
</script>